import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";
import { useHistory, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

const GetAccessEmails = () => {
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]);

  const getAccessEmails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://dashboard.balm.ai/api/v15/getaccess`
      );
      if (response) {
        setEmails(response?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    getAccessEmails();
  }, []);

  return (
    <>
      <Topbar />
      <div className="container ">
        <Sidebar />
        <div className="withdrwal-list">
          <span className="widgetSmTitle">Get Early Access Emails</span>
          {loading ? (
            <p>loading...</p>
          ) : (
            <table className="widgetLgTable">
              <tr className="widgetLgTr">
                <th className="widgetLgTh">S/N</th>
                <th className="widgetLgTh">Emails</th>
              </tr>
              {emails &&
                emails?.map((data, index) => (
                  <tr className="widgetLgTr" key={index}>
                    <td className="widgetLgAmount">{index + 1}</td>
                    <td className="widgetLgUser">
                      <span className="widgetLgName">{data?.email}</span>
                    </td>
                  </tr>
                ))}
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default GetAccessEmails;
