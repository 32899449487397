import React, { useState, useEffect } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./user.css";
import {
  CalendarToday,
  LocationSearching,
  PermIdentity,
  PhoneAndroid,
} from "@material-ui/icons";

import Sidebar from "../../components/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";
import { detailsUser, updateUser } from "../../actions/userActions";

export default function User() {
  const [user, setUser] = useState({});
  const [dob, setDob] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const userUpdate = useSelector((state) => state.userUpdate);

  let { userId } = useParams();

  useEffect(() => {
    if (userId) {
      dispatch(detailsUser(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (userDetails.user) {
      setUser(userDetails?.user);
    }
  }, [userDetails]);

  useEffect(() => {
    if (user.dob) {
      let date = new Date(user.dob).toLocaleDateString();
      setDob(date);
    }
  }, [user]);

  const verifyUser = async () => {
    let data = {
      _id: user._id,
      isVerified: !user.isVerified,
      accountStatus: "active",
    };
    if (
      window.confirm(
        "Do you really want to change the verification status of this user"
      )
    ) {
      await dispatch(updateUser(data));
      dispatch(detailsUser(userId));
    }
  };

  return (
    <>
      <Topbar />
      <div className="container">
        <Sidebar />
        <div className="user">
          <div className="userContainer">
            {/* biodata */}
            <div className="userUpdate">
              <span className="userUpdateTitle">User Bio Data</span>
              <div className="userShowTop">
                <img
                  src={
                    user.profileImg?.url ||
                    "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                  }
                  alt=""
                  className="userShowImg"
                />
                <div className="userShowTopTitle">
                  <span className="userShowUsername">{user.name}</span>
                  <span className="userShowUserTitle">{user.email}</span>
                </div>
              </div>

              <div className="userShowBottom">
                <span className="userShowTitle">Account Details</span>
                <div className="userShowInfo">
                  <PermIdentity className="userShowIcon" />
                  <span className="userShowInfoTitle">{user.email}</span>
                </div>
                <div className="userShowInfo">
                  <CalendarToday className="userShowIcon" />
                  <span className="userShowInfoTitle">{dob}</span>
                </div>
                <div className="userShowInfo">
                  <PhoneAndroid className="userShowIcon" />
                  <span className="userShowInfoTitle">{user.phone}</span>
                </div>
                <div className="userShowInfo">
                  <LocationSearching className="userShowIcon" />
                  <span className="userShowInfoTitle">
                    {user.address1} {user.address2} {user.city} {user.state}{" "}
                    {user.country}
                  </span>
                </div>
                <span className="userShowTitle">Demographics</span>
                <div className="userShowInfo">
                  <span className="userShowIcon">Race:</span>
                  <span className="userShowInfoTitle">{user.race}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Language:</span>
                  <span className="userShowInfoTitle">{user.language}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Marital Status:</span>
                  <span className="userShowInfoTitle">
                    {user.maritalStatus}
                  </span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Genotype:</span>
                  <span className="userShowInfoTitle">{user.genotype}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Blood Group:</span>
                  <span className="userShowInfoTitle">{user.bloodGroup}</span>
                </div>
                <span className="userShowTitle">Health Goals</span>
                <div className="userShowInfo">
                  <span className="userShowIcon">Health Goals:</span>
                  <span className="userShowInfoTitle">{user.healthGoals}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Blood Pressure:</span>
                  <span className="userShowInfoTitle">
                    {user.bloodPressure}
                  </span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Pulse:</span>
                  <span className="userShowInfoTitle">{user.pulse}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Temperature:</span>
                  <span className="userShowInfoTitle">{user.temperature}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Weight:</span>
                  <span className="userShowInfoTitle">{user.weight}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Height:</span>
                  <span className="userShowInfoTitle">{user.height}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">BMI:</span>
                  <span className="userShowInfoTitle">{user.bmi}</span>
                </div>
                <span className="userShowTitle">Allergies</span>
                <div className="userShowInfo">
                  <span className="userShowIcon">Allergies:</span>
                  <span className="userShowInfoTitle">{user.allergies}</span>
                </div>
              </div>
            </div>

            {/* medical */}
            <div className="userUpdate">
              <span className="userUpdateTitle">User Medical History</span>
              <div className="userShowBottom">
                <span className="userShowTitle">Social History</span>
                <div className="userShowInfo">
                  <span className="userShowIcon">Tobacco Use:</span>
                  <span className="userShowInfoTitle">{user.tobaccoUse}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Alcohol Use:</span>
                  <span className="userShowInfoTitle">{user.alcoholUse}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Substance Use:</span>
                  <span className="userShowInfoTitle">{user.substanceUse}</span>
                </div>
                <span className="userShowTitle">Lifestyle</span>
                <div className="userShowInfo">
                  <span className="userShowIcon">Exercise:</span>
                  <span className="userShowInfoTitle">{user.exercise}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Junk Food:</span>
                  <span className="userShowInfoTitle">{user.junkFood}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Water:</span>
                  <span className="userShowInfoTitle">{user.water}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Walks:</span>
                  <span className="userShowInfoTitle">{user.walks}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Healthy Meals:</span>
                  <span className="userShowInfoTitle">{user.healthyMeals}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Sleep:</span>
                  <span className="userShowInfoTitle">{user.sleep}</span>
                </div>
                <span className="userShowTitle">Medications</span>
                <div className="userShowInfo">
                  <span className="userShowIcon">Medication 1:</span>
                  <span className="userShowInfoTitle">{user.medication1}</span>
                  <span className="userShowIcon">Date 1:</span>
                  <span className="userShowInfoTitle">{user.date1}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Medication 2:</span>
                  <span className="userShowInfoTitle">{user.medication2}</span>
                  <span className="userShowIcon">Date 2:</span>
                  <span className="userShowInfoTitle">{user.date2}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Medication 3:</span>
                  <span className="userShowInfoTitle">{user.medication3}</span>
                  <span className="userShowIcon">Date 3:</span>
                  <span className="userShowInfoTitle">{user.date3}</span>
                </div>
                <span className="userShowTitle">Active Health Problems</span>
                <div className="userShowInfo">
                  <span className="userShowIcon">Health Problem 1:</span>
                  <span className="userShowInfoTitle">
                    {user.healthProblem1}
                  </span>
                  <span className="userShowIcon">Date Diagnosed :</span>
                  <span className="userShowInfoTitle">
                    {user.dateDiagnosed1}
                  </span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Health Problem 2:</span>
                  <span className="userShowInfoTitle">
                    {user.healthProblem2}
                  </span>
                  <span className="userShowIcon">Date Diagnosed :</span>
                  <span className="userShowInfoTitle">
                    {user.dateDiagnosed2}
                  </span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Health Problem 3:</span>
                  <span className="userShowInfoTitle">
                    {user.healthProblem3}
                  </span>
                  <span className="userShowIcon">Date Diagnosed :</span>
                  <span className="userShowInfoTitle">
                    {user.dateDiagnosed3}
                  </span>
                </div>
                <span className="userShowTitle">Resolved Health Problems</span>
                <div className="userShowInfo">
                  <span className="userShowIcon">Resolved Problem 1:</span>
                  <span className="userShowInfoTitle">
                    {user.resolvedProblem1}
                  </span>
                  <span className="userShowIcon">Resolved Date :</span>
                  <span className="userShowInfoTitle">
                    {user.resolvedDate1}
                  </span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Resolved Problem 2:</span>
                  <span className="userShowInfoTitle">
                    {user.resolvedProblem2}
                  </span>
                  <span className="userShowIcon">Resolved Date :</span>
                  <span className="userShowInfoTitle">
                    {user.resolvedDate2}
                  </span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Resolved Problem 3:</span>
                  <span className="userShowInfoTitle">
                    {user.resolvedProblem3}
                  </span>
                  <span className="userShowIcon">Resolved Date :</span>
                  <span className="userShowInfoTitle">
                    {user.resolvedDate3}
                  </span>
                </div>
                <span className="userShowTitle">Immunizations</span>
                <div className="userShowInfo">
                  <span className="userShowIcon">Immunization 1:</span>
                  <span className="userShowInfoTitle">
                    {user.immunization1}
                  </span>
                  <span className="userShowIcon">Date Taken :</span>
                  <span className="userShowInfoTitle">{user.dateTaken1}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Immunization 2:</span>
                  <span className="userShowInfoTitle">
                    {user.immunization2}
                  </span>
                  <span className="userShowIcon">Date Taken :</span>
                  <span className="userShowInfoTitle">{user.dateTaken2}</span>
                </div>
                <div className="userShowInfo">
                  <span className="userShowIcon">Immunization 3:</span>
                  <span className="userShowInfoTitle">
                    {user.immunization3}
                  </span>
                  <span className="userShowIcon">Date Taken :</span>
                  <span className="userShowInfoTitle">{user.dateTaken3}</span>
                </div>

                {/* <div className="userUpdate">
                  <span className="userUpdateTitle">User Medical Problems</span>
                  <div className="userShowInfo">
                    {user?.medicalProblems?.map((problem) => (
                      <span key={problem?._id} className="userShowInfoTitle">
                        {problem}
                      </span>
                    ))}
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="userUpdate">
              <span className="userUpdateTitle">User Medical Problems</span>
              <div className="userShowInfo">
                {user?.medicalProblems?.map((problem) => (
                  <span key={problem?._id} className="userShowInfoTitle">
                    {problem}
                  </span>
                ))}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
