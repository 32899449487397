

export const EXPERT_LIST_REQUEST = 'EXPERT_LIST_REQUEST';
export const EXPERT_LIST_SUCCESS = 'EXPERT_LIST_SUCCESS';
export const EXPERT_LIST_FAIL = 'EXPERT_LIST_FAIL';

export const EXPERT_UPDATE_PROFILE_REQUEST = 'EXPERT_UPDATE_PROFILE_REQUEST';
export const EXPERT_UPDATE_PROFILE_SUCCESS = 'EXPERT_UPDATE_PROFILE_SUCCESS';
export const EXPERT_UPDATE_PROFILE_FAIL = 'EXPERT_UPDATE_PROFILE_FAIL';
export const EXPERT_UPDATE_PROFILE_RESET = 'EXPERT_UPDATE_PROFILE_RESET';

export const EXPERT_UPDATE_REQUEST = 'EXPERT_UPDATE_REQUEST';
export const EXPERT_UPDATE_SUCCESS = 'EXPERT_UPDATE_SUCCESS';
export const EXPERT_UPDATE_FAIL = 'EXPERT_UPDATE_FAIL';
export const EXPERT_UPDATE_RESET = 'EXPERT_UPDATE_RESET';

export const EXPERT_DELETE_REQUEST = 'EXPERT_DELETE_REQUEST';
export const EXPERT_DELETE_SUCCESS = 'EXPERT_DELETE_SUCCESS';
export const EXPERT_DELETE_FAIL = 'EXPERT_DELETE_FAIL';
export const EXPERT_DELETE_RESET = 'EXPERT_DELETE_RESET';

export const EXPERT_DETAILS_REQUEST = 'EXPERT_DETAILS_REQUEST';
export const EXPERT_DETAILS_SUCCESS = 'EXPERT_DETAILS_SUCCESS';
export const EXPERT_DETAILS_FAIL = 'EXPERT_DETAILS_FAIL';
export const EXPERT_DETAILS_RESET = 'EXPERT_DETAILS_RESET';
