import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Home from "./pages/home/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import UserList from "./pages/userList/UserList";
import User from "./pages/user/User";
import NewUser from "./pages/newUser/NewUser";
import ExpertList from "./pages/expertList/ExpertList";
import { signout } from "./actions/userActions";
import { useDispatch } from "react-redux";
import Expert from "./pages/expert/Expert";
import NewProduct from "./pages/newProduct/NewProduct";
import Auth from "./pages/auth/Auth";
import Signin from "./pages/signin/Signin";
import WithdrawList from "./pages/withdralList/WithdrawList";
import AppointmentsList from "./pages/appointmentsList/AppointmentsList";
import PrivateRoute from "./components/PrivateRoute";
import GetAccessEmails from "./pages/getAccessEmails/GetAccessEmails";

function App() {
  const old = sessionStorage.getItem("userInfo");
  const now = new Date().getTime();
  const dispatch = useDispatch();
  useEffect(() => {
    const oldt = JSON.parse(sessionStorage.getItem("userInfo"));
    console.log(oldt?.expires);
    console.log(now);
    if (now > oldt?.expires) {
      dispatch(signout);
      sessionStorage.removeItem("userInfo");
     localStorage.removeItem("userInfo");
      // toast.error("❌  Your Session Has Expired");
    }
  }, [now, old]);

  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Switch>
        <Route exact path="/" component={Auth} />

        <Route
          exact
          component={Signin}
          path="/auth/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MmY0ZTA2ZTQyZDU1OGJkNzg5NWI4NDEiLCJuYW1lIjoiQmFsbSBBSSIsImVtYWlsIjoiYmFsbXRlY2hub2xvZ2llc0BnbWFpbC5jb20iLCJpc0V4cGVydCI6ZmFsc2UsImlhdCI6MTY2MDIyMTAyMiwiZXhwIjoxNjg2MTQxMDIyfQ.DCgm_0eTyhUjoaWYZVOdNgbXOhQ-1eabA7kBlGrypvs/:emailId"
        />
        <PrivateRoute exact path="/home" component={Home} />
        <PrivateRoute exact path="/users" component={UserList} />
        <PrivateRoute exact path="/user/:userId" component={User} />
        <PrivateRoute path="/appointments" exact component={AppointmentsList} />
        <PrivateRoute
          path="/appointments/:appointmentId"
          exact
          component={User}
        />
        <PrivateRoute path="/newUser" exact component={NewUser} />
        <PrivateRoute path="/getAccessEmails" exact component={GetAccessEmails} />
        <PrivateRoute path="/experts" exact component={ExpertList} />
        <PrivateRoute path="/expert/:expertId" exact component={Expert} />
        <PrivateRoute
          path="/withdrawals/:withdrawalId"
          exact
          component={Expert}
        />
        <PrivateRoute path="/withdrawals" exact component={WithdrawList} />
        <PrivateRoute path="/newexpert" exact component={NewProduct} />
      </Switch>
      {/* </div> */}
    </Router>
  );
}

export default App;
